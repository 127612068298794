



















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { WPPost } from '../../../../types/typescript-axios/api';
import Swiper, { SwiperOptions } from 'swiper';
import CourseCard from './CourseCard.vue';
// 参考サイト
// https://insider.10bace.com/2019/02/06/vue-js%E3%81%A8swiper%E3%81%A8%E6%99%82%E3%80%85axios/

// const swiperOptions: SwiperOptions = {
//   loop: true,
//   slidesPerView: 3,
//   spaceBetween: 15,
//   loopedSlides: 6, // NOTE poster数÷2 以上
//   speed: 700,
//   centeredSlides: true,
//   grabCursor: true,
//   pagination: {
//     el: '.swiper-pagination',
//   },
// };

@Component({
  components: {
    CourseCard,
  },
})
export default class CourseList extends Vue {
  private breakpoint: number = 767;
  private mySwiperBool: boolean = false;
  private swiperOptions: SwiperOptions = {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  loopedSlides: 6, // NOTE poster数÷2 以上
  speed: 700,
  centeredSlides: true,
  grabCursor: true,
  pagination: {
    el: '.swiper-pagination',
  },
};
  @Prop({default: () => []})
  private slides!: WPPost[];

  @Prop({default: 0})
  private total!: number;

  @Prop({default: -1})
  private isStatus!: number;

  private slidesCount: number = 0;
  private mySwiper!: Swiper;

  @Prop({default: ''})
  private swiperInitKey!: string;

  get swiperKey(): string {
    return `swiper-key-${this.swiperInitKey}`;
  }

  @Emit()
  private createSwiper() {
    const $ref: any = this.$refs;
    if (this.slides.length !== this.slidesCount) {
      if (typeof $ref.swiper !== 'undefined') {
        if (this.slides.length > 1) {
          this.swiperOptions.loop = true;
        } else {
          this.swiperOptions.loop = false;
        }
        this.mySwiper = new Swiper($ref.swiper, this.swiperOptions);
        this.slidesCount = this.slides.length;
      }
    }
  }

  @Emit()
  private handleResize() {
    if (this.breakpoint < window.innerWidth && this.mySwiperBool) {
      this.mySwiper.destroy(false, true);
      this.slidesCount = 0;
      this.mySwiperBool = false;
    } else if (this.breakpoint >= window.innerWidth && !(this.mySwiperBool)) {
      this.createSwiper();
      this.mySwiperBool = true;
    }
  }

  private mounted(): void {
    this.$nextTick().then(() => {
      const $ref: any = this.$refs;
      if (this.breakpoint < window.innerWidth) {
        this.mySwiperBool = false;
      } else {
        this.createSwiper();
        this.mySwiperBool = true;
      }
      window.addEventListener('resize', this.handleResize, false);
    });
  }
  private destroyed() {
    window.removeEventListener('resize', this.handleResize, false);
  }
}
